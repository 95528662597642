import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { api } from "../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import defaultRestOptions from "../utils/defaultRestOptions";

async function getOrders(filters = {}) {
  const url = api.order.orders();
  if (filters) setQueryFilters(filters, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getOrder(orderId) {
  const url = api.order.order(orderId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function setOrder(order) {
  const url = api.order.order(order);
  url.addParameter("action", "SET_ACTIVE");
  const request = new Request(url, Method.POST);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createOrder(data) {
  const url = api.order.orders();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateOrder(orderId, data) {
  const url = api.order.order(orderId);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getCategories() {
  const url = api.order.category();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateCart(order, content) {
  const url = api.order.cart(order, content);
  url.addParameter("action", "ADD_TO_CART");
  const request = new Request(url, Method.POST);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getForm(order, content) {
  const url = api.order.form(order, content);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateForm(order, content, data) {
  const url = api.order.form(order, content);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getContent({ contentId }) {
  const url = api.order.content(contentId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createContent(data) {
  const url = api.order.content();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}
async function createContentWithParent(data, orderId) {
  const url = api.order.createContentWithParent(orderId);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateContent(content, data) {
  const url = api.order.content(content);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getMake() {
  const url = api.order.make();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateMake(data) {
  const url = api.order.make();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getAccessories(orderId, contentId) {
  const url = api.order.accessories(orderId, contentId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateAccessories(orderId, contentId, data) {
  const url = api.order.accessories(orderId, contentId);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function createExternalProduct(data) {
  const url = api.order.content();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateExternalProduct(data) {
  const url = api.order.content();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getDelivery(order, content) {
  const url = api.order.delivery(order, content);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateDelivery(data, orderId, contentId) {
  const url = api.order.delivery(orderId, contentId);

  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getType(order, content) {
  const url = api.order.type(order, content);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function uploadFile(contentId, fittingId, data) {
  let url;
  if (fittingId) {
    url = api.webOrders.files(fittingId);
  } else {
    url = api.order.files(contentId);
  }
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function deleteFile(fileId, fittingId, orderId, contentId) {
  let url;
  if (fittingId) {
    url = api.webOrders.deleteFile(fittingId, fileId);
  } else {
    url = api.order.deleteFile(orderId, contentId, fileId);
  }
  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function deleteOrder(orderId) {
  const url = api.order.order(orderId);
  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function deleteOrderItem(contentId) {
  const url = api.order.content(contentId);
  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateLine(contentId, data) {
  const url = api.order.line(contentId);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function sendOrder(order) {
  const url = api.order.send(order);
  url.addParameter("action", "SEND");
  const request = new Request(url, Method.POST);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function revertContentToDraft({ contentId }) {
  const url = api.webOrders.content({ contentId });
  url.addParameter("action", "REVERT_TO_DRAFT");
  const request = new Request(url, Method.POST);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getFittings(filter = {}) {
  const url = api.webOrders.fittings();

  if (!Object.prototype.hasOwnProperty.call(filter, "isAdminCall")) filter.isAdminCall = false;
  if (!Object.prototype.hasOwnProperty.call(filter, "all")) filter.all = false;
  if (filter) setQueryFilters(filter, url);

  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getFitting(id) {
  const url = api.webOrders.fittings(id);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateFitting(fittingId, data) {
  const url = api.webOrders.fittings(fittingId);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function closeFitting(id, action) {
  const url = api.webOrders.fittings(id);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, { action: action }, await defaultRestOptions());
}

async function getFittingMeeting(meetingId) {
  const url = api.webOrders.meetings(meetingId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function releaseFittingMeetings(meetingIds) {
  const url = api.webOrders.meetings();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, { action: "RELEASE_MEETINGS", payload: meetingIds }, await defaultRestOptions());
}

async function regretReleaseFittingMeetings(meetingIds) {
  const url = api.webOrders.meetings();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, { action: "UNRELEASE_MEETINGS", payload: meetingIds }, await defaultRestOptions());
}

async function getFittingTypes() {
  const url = api.webOrders.fittingTypes();
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function createFittingMeeting(data) {
  const url = api.webOrders.meetings();
  const request = new Request(url, Method.POST);
  return await sendRequest(request, { action: "RESERVE_SLOT", payload: data }, await defaultRestOptions());
}

async function updateFittingMeetings(data) {
  const url = api.webOrders.meetings();
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function updateFittingMeeting(fittingId, meetingId, data) {
  const url = api.webOrders.fittingMeeting(fittingId, meetingId);
  const request = new Request(url, Method.PUT);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function createMeetingForFitting(fittingId, data) {
  const url = api.webOrders.fittingMeeting(fittingId);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}
async function annulFittingMeeting(fittingId, meetingId) {
  const url = api.webOrders.fittingMeeting(fittingId, meetingId);
  const request = new Request(url, Method.POST);

  return await sendRequest(request, { action: "CANCEL" }, await defaultRestOptions());
}

async function getFittingOrders(fittingId, filters = {}) {
  const url = api.webOrders.fittingOrders(fittingId);

  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, null, await defaultRestOptions());
}

async function createComment(fittingId, data) {
  const url = api.webOrders.createComment(fittingId);
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function deleteComment(commentId) {
  const url = api.webOrders.deleteComment(commentId);
  const request = new Request(url, Method.DELETE);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getOrderContent({ orderId, contentId }) {
  const url = api.webOrders.content({ webOrderId: orderId, contentId });
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getOrderContentFiles({ orderId, contentId }) {
  const url = api.webOrders.contentFiles(orderId, contentId);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function updateOrderContent({ orderId, contentId, data }) {
  const url = api.webOrders.content({ webOrderId: orderId, contentId });
  const request = new Request(url, Method.POST);
  return await sendRequest(request, data, await defaultRestOptions());
}

async function getFiltersForOrders(filters = {}) {
  const url = api.order.filter();
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getClientHoldingsByContent({ filters = {}, orderId, contentId }) {
  const url = api.order.clientHoldings(orderId, contentId);
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

async function getClientHoldings({ filters = {}, orderId }) {
  const url = api.order.clientHoldings(orderId);
  if (filters) setQueryFilters(filters, url);
  const request = new Request(url, Method.GET);
  return await sendRequest(request, null, await defaultRestOptions());
}

export default {
  getOrders,
  getOrder,
  setOrder,
  createOrder,
  updateOrder,
  getCategories,
  updateCart,
  getForm,
  updateForm,
  getContent,
  createContent,
  updateContent,
  getMake,
  updateMake,
  getAccessories,
  updateAccessories,
  createExternalProduct,
  updateExternalProduct,
  getDelivery,
  updateDelivery,
  getType,
  uploadFile,
  deleteFile,
  deleteOrder,
  deleteOrderItem,
  updateLine,
  sendOrder,
  createContentWithParent,
  revertContentToDraft,
  getFittings,
  getFitting,
  updateFitting,
  closeFitting,
  getFittingTypes,
  getFittingMeeting,
  createFittingMeeting,
  releaseFittingMeetings,
  regretReleaseFittingMeetings,
  updateFittingMeetings,
  updateFittingMeeting,
  createMeetingForFitting,
  annulFittingMeeting,
  getFittingOrders,
  getOrderContent,
  getOrderContentFiles,
  updateOrderContent,
  createComment,
  deleteComment,
  getFiltersForOrders,
  getClientHoldingsByContent,
  getClientHoldings,
};
