import { TextArea } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import ConfirmModal from "../../../shared/Modals/ConfirmModal";

const CreateCommentModal = ({ visible, type, onSubmit, onCancel }) => {
  const translate = useTranslate();
  const [comment, setComment] = useState("");

  const cancel = () => {
    setComment("");
    onCancel();
  };

  return (
    <ConfirmModal
      title={translate(`NEW_${type}_COMMENT`)}
      visible={visible}
      cancel={() => cancel()}
      submit={() => onSubmit(comment)}
      declineButtonText={translate("CANCEL")}
      confirmButtonText={translate("CREATE")}
      small={false}
    >
      <TextArea placeholder={translate("WRITE_COMMENT")} value={comment} onChange={e => setComment(e.target.value)} />
    </ConfirmModal>
  );
};

CreateCommentModal.propTypes = {
  visible: PropType.bool.isRequired,
  setVisible: PropType.func.isRequired,
  type: PropType.string.isRequired,
  onSubmit: PropType.func.isRequired,
  onCancel: PropType.func.isRequired,
};

export default CreateCommentModal;
