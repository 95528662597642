import { Button, IconButton } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useCallback, useState } from "react";
import { Trash } from "../../../assets/icons";
import { useTranslate } from "../../../language/i18n";
import orderService from "../../../services/order/orderService";
import styles from "./Comments.module.scss";
import CreateCommentModal from "./Modal/CreateCommentModal";
import DeleteCommentModal from "./Modal/DeleteCommentModal";

const Comments = ({ fittingId, comments, type, setFetchFittings }) => {
  const translate = useTranslate();
  const [chosenComment, setChosenComment] = useState(null);
  const [createCommentVisible, setCreateCommentVisible] = useState(false);
  const [deleteCommentVisible, setDeleteCommentVisible] = useState(false);

  const onSend = useCallback(
    async comment => {
      const data = {
        action: type,
        payload: comment,
      };
      const response = await orderService.createComment(fittingId, data);

      if (response.isOk()) {
        setCreateCommentVisible(false);
        setFetchFittings(true);
      }
    },
    [fittingId, type, setFetchFittings],
  );

  const onDelete = comment => {
    setChosenComment(comment);
    setDeleteCommentVisible(true);
  };

  const onDeleteComment = async () => {
    await orderService.deleteComment(chosenComment.id);
    setDeleteCommentVisible(false);
    setChosenComment(null);
    setFetchFittings(true);
  };

  return (
    <section>
      <h3 className={styles.title}>{translate(`${type}_COMMENTS`)}</h3>
      {!!comments?.length &&
        comments.map(comment => {
          return (
            <div key={comment.id} className={styles.commentCard}>
              <div className={styles.header}>
                <span className={styles.author}>{comment.createdBy}</span>
                <div className={styles.right}>
                  <span className={styles.timestamp}>{comment.createdDateTime}</span>
                  <IconButton onClick={() => onDelete(comment)} customCssClass={styles.delete}>
                    <Trash />
                  </IconButton>
                </div>
              </div>

              <div className={styles.message}>{comment.comment}</div>
            </div>
          );
        })}
      <div className={styles.button}>
        <Button onClick={() => setCreateCommentVisible(true)}>{translate("CREATE")}</Button>
      </div>

      <CreateCommentModal
        visible={createCommentVisible}
        setVisible={setCreateCommentVisible}
        type={type}
        onSubmit={onSend}
        onCancel={() => setCreateCommentVisible(false)}
      />
      <DeleteCommentModal
        visible={deleteCommentVisible}
        setVisible={setDeleteCommentVisible}
        onSubmit={onDeleteComment}
        onCancel={() => setDeleteCommentVisible(false)}
      />
    </section>
  );
};

Comments.propTypes = {
  fittingId: PropType.string,
  comments: PropType.object,
  type: PropType.string,
  setFetchFittings: PropType.func,
};

export default Comments;
